<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="tab-pane fade show active text-center" v-else-if="Object.keys(stat).length === 0">
        <p>{{$t('football.match-detail.No Stats found')}}</p>
    </div>
    <div class="fixtured_live_center_content" v-else>
        <div class="ftb_details_tabs_content">
            <div class="tab-content">
                <div class="tab-pane active" id="stats">
                    <div class="ftb_stats_section" id="statsContainer">
                      <div class="sabc_stats_head">
                        <h2>
                          <!-- Marumo Gallats FC -->
                        <span>
                            {{$getSafe(()=>$filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors, true).name)}}
                        </span>
                            <span>
                            <!-- {{$getSafe(()=>$filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors, false).name)}} -->
                        </span>
                        </h2>
                        <h2>
                          <!-- Chippa United -->
                        <span>
                            <!-- {{$getSafe(()=>$filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors, true).name)}} -->
                        </span>
                            <span>
                            {{$getSafe(()=>$filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors, false).name)}}
                        </span>
                        </h2>
                      </div>
                        <div class="ftb_details_header_section ftb_details_header_section_diff" id="livematchstats">
                            <div class="ftb_stats_section">
                                <div class="ftb_stats_list_row">
                                    <div class="ftb_stats_list_col">
                                        <span>{{stat.ht_corner_kicks}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:stat.ht_corner_kicksPr+'%'}"
                                                class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>{{$t('football.match-detail.Corners')}}</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:stat.at_corner_kicksPr+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{stat.at_corner_kicks}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row">
                                    <div class="ftb_stats_list_col">
                                        <span>{{stat.ht_shots_on_goal}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:stat.ht_shots_on_goalPr+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>{{$t('football.match-detail.Shots On Target')}}</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:stat.at_shots_on_goalPr+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{stat.at_shots_on_goal}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row">
                                    <div class="ftb_stats_list_col">
                                        <span>{{stat.ht_possession_percentage}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:stat.ht_possession_percentage+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>{{$t('football.match-detail.Possession')}}</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:stat.at_possession_percentage+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{stat.at_possession_percentage}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row">
                                    <div class="ftb_stats_list_col">
                                        <span>{{stat.ht_yellow_cards}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:stat.ht_yellow_cardsPr+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>{{$t('football.match-detail.Yellow Cards')}}</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:stat.at_yellow_cardsPr+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{stat.at_yellow_cards}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row">
                                    <div class="ftb_stats_list_col">
                                        <span>{{stat.ht_red_cards}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:stat.ht_red_cardsPr+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>{{$t('football.match-detail.Red Cards')}}</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:stat.at_red_cardsPr+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{stat.at_red_cards}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row">
                                    <div class="ftb_stats_list_col">
                                        <span>{{stat.ht_total_assists}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:stat.ht_total_assistsPr+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>{{$t('football.match-detail.Shots Off Target')}}</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:stat.at_total_assistsPr+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{stat.at_total_assists}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row">
                                    <div class="ftb_stats_list_col">
                                        <span>{{stat.ht_attacks}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:stat.ht_attacksPr+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>{{$t('football.match-detail.Attacks')}}</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:stat.at_attacksPr+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{stat.at_attacks}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row">
                                    <div class="ftb_stats_list_col">
                                        <span>{{stat.ht_dangerous_attacks}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:stat.ht_dangerous_attacksPr+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>{{$t('football.match-detail.Dangerous')}}</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:stat.at_dangerous_attacksPr+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{stat.at_dangerous_attacks}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    export default {
        name: 'MatchDetailStats',
        data() {
            return {
                stat: {},
            }
        },

        serverPrefetch() {
            return this.getStat();
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            if(this.$store.state.fbMatchDetailResponse && this.$store.state.fbMatchDetailResponse.data){
                var replacements = {
                    '%HOME_TEAM%': this.$filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors, true).name,
                    '%AWAY_TEAM%': this.$filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors, false).name,
                    '%DATE%' : this.$route.params.date,
                };
    
                let title = this.$getSafe(() => this.$replacePlaceHolders(ss.football_match_stats_meta_title,replacements));
                let description = this.$getSafe(() => this.$replacePlaceHolders(ss.football_match_stats_meta_description,replacements));
                let keywords = this.$getSafe(() => ss.football_match_stats_meta_keywords);
    
                let canonical = this.$toFullPath(this.$route.fullPath).replace('stats', 'overview');
    
                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link:[
                        {rel: 'canonical', href: canonical}],
                };
            }
        },
        mounted() {
            this.getStat();
        },

        methods: {
            getStat(inning) {
                this.is_data_ready = false;
                return this.$axios
                    .get(this.$FB_MATCH_STAT_API.replace(':match', this.$route.params.matchId))
                    .then(response => {
                        if (response.data.stat.match) {
                            this.stat = response.data.stat;
                            this.makePercantageValues();
                        }
                        this.is_data_ready = true;
                    })
            },
            makePercantageValues(){
                this.stat.ht_corner_kicksPr = this.stat.ht_corner_kicks ? this.getPercentage(this.stat.ht_corner_kicks,this.stat.at_corner_kicks) : 0;
                this.stat.at_corner_kicksPr = this.stat.at_corner_kicks ? (100 - this.stat.ht_corner_kicksPr) : 0;
                this.stat.ht_total_assistsPr = this.stat.ht_total_assists ? this.getPercentage(this.stat.ht_total_assists,this.stat.at_total_assists) : 0;
                this.stat.at_total_assistsPr = this.stat.at_total_assists ? (100 - this.stat.ht_total_assistsPr) : 0;
                this.stat.ht_shots_on_goalPr = this.stat.ht_shots_on_goal ? this.getPercentage(this.stat.ht_shots_on_goal,this.stat.at_shots_on_goal) :0;
                this.stat.at_shots_on_goalPr = this.stat.at_shots_on_goal ? (100 - this.stat.ht_shots_on_goalPr) : 0;
                this.stat.ht_yellow_cardsPr = this.stat.ht_yellow_cards ? this.getPercentage(this.stat.ht_yellow_cards,this.stat.at_yellow_cards) : 0;
                this.stat.at_yellow_cardsPr = this.stat.at_yellow_cards ? (100 - this.stat.ht_yellow_cardsPr) : 0;
                this.stat.ht_red_cardsPr = this.stat.ht_red_cards ? this.getPercentage(this.stat.ht_red_cards,this.stat.at_red_cards) : 0;
                this.stat.at_red_cardsPr = this.stat.at_red_cards ? (100 - this.stat.ht_red_cardsPr) : 0;
                this.stat.ht_attacksPr = this.stat.ht_attacks ? this.getPercentage(this.stat.ht_attacks,this.stat.at_attacks) : 0;
                this.stat.at_attacksPr = this.stat.at_attacks ? (100 - this.stat.ht_attacksPr) : 0;
                this.stat.ht_dangerous_attacksPr = this.stat.ht_dangerous_attacks ? this.getPercentage(this.stat.ht_dangerous_attacks,this.stat.at_dangerous_attacks) : 0;
                this.stat.at_dangerous_attacksPr = this.stat.at_dangerous_attacks ? (100 - this.stat.ht_dangerous_attacksPr) : 0;
                this.stat.ht_totalPr = this.stat.ht_total ? this.getPercentage(this.stat.ht_total,this.stat.at_total) : 0;
                this.stat.at_totalPr = this.stat.at_total ? (100 - this.stat.ht_totalPr) : 0;
            },

            getPercentage(ht_value,at_value){
                return ((ht_value*100)/(ht_value+at_value));
            }
        }
    }

</script>
<style scoped>
.fixtured_live_center_content{background: #ffffff;}
</style>
